import { Form } from "antd";
import { useState } from "react";

import {
  riskYieldState,
  useRefreshRiskYield
} from "@src/state/risk-yield.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { userUriState } from "@states/auth.state";
import { currentDepotIDState } from "@states/current.depot.state";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import { useIsUnderageMode } from "@page-components/onboarding/hooks";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import { useRefreshState } from "@src/state/request.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { isValidRiskToStrategy } from ".";
import { RiskValidateModalContent } from "../RiskValidateModalContent";

export const useInvestmentRiskForm = (
  currentStrategy: FinancialPositionDto["strategy"],
  destroyModal: () => void,
  onResetParentField: () => void
) => {
  const [form] = Form.useForm();

  const { contextHolder, openModal } = useThemedModal();

  const isUnderageMode = useIsUnderageMode();

  const [, currentDepotID] = useAsyncSelector(currentDepotIDState);

  const appUserEnvironment = isUnderageMode
    ? "activeOnboardingOwner"
    : "loggedInUser";

  const [, userUri] = useAsyncSelector(userUriState(appUserEnvironment));

  const [, riskYield] = useAsyncSelector(riskYieldState(appUserEnvironment));

  const refreshRiskYield = useRefreshRiskYield(appUserEnvironment);

  const refreshDepotDetails = useRefreshState(
    "depotDetails",
    "currentDepotOwner",
    currentDepotID
  );

  const riskInitialValue = riskYield?.anlagerisiko ?? 15;

  const [isSubmit, setIsSubmit] = useState(false);

  const [currentRiskValue, setCurrentRiskValue] = useState(riskInitialValue);

  const isValidRiskValue = isValidRiskToStrategy(
    currentStrategy,
    currentRiskValue
  );

  const labels = useOnboardingLabels();

  const isUnderage = useIsUnderageMode();

  const onSliderAfterChange = async (value: number) => {
    const validationResult = await smavestoCore.services.validation.validate(
      "validate-risk",
      `risk=${value}`,
      userUri
    );

    if (!userUri && value < 7) {
      form.setFieldsValue({ investmentRisk: riskInitialValue });
      openModal("info", {
        ...labels.investmentSettings.warningModalSettings.investmentRisk,
        content: <RiskValidateModalContent isUnderage={isUnderage} />
      });
    } else if (userUri && isApiErrorResponse(validationResult)) {
      form.setFieldsValue({ investmentRisk: riskInitialValue });
      openModal("info", {
        ...labels.investmentSettings.warningModalSettings.investmentRisk,
        content: <RiskValidateModalContent isUnderage={isUnderage} />
      });
    } else {
      setCurrentRiskValue(value);
    }
  };

  const onFinish = async ({ investmentRisk }: { investmentRisk: number }) => {
    setIsSubmit(true);

    if (!investmentRisk || !riskYield || !userUri) return;

    const body = { ...riskYield, anlagerisiko: investmentRisk };

    await smavestoCore.services.user.setRiskYield(userUri, body);

    refreshRiskYield();
    refreshDepotDetails();

    setIsSubmit(false);
    destroyModal();
  };

  const onCancel = () => {
    onResetParentField();
    destroyModal();
  };

  return {
    riskInitialValue,
    form,
    onSliderAfterChange,
    onFinish,
    onCancel,
    isSubmit,
    isValidRiskValue,
    contextHolder
  };
};

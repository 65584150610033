import type { FormProps } from "antd";
import { Form } from "antd";

import { useContext } from "react";
import { ThemeContext } from "@pages/_app";

interface Props<T> extends FormProps<T> {
  title?: string;
  text?: string;
  withoutHeader?: boolean;
}

export function FormInModal<T extends Record<string, unknown> = any>({
  title,
  text,
  withoutHeader,
  style,
  children,
  ...formProps
}: Props<T>) {
  const { theme, appModeTheme } = useContext(ThemeContext);

  return (
    <Form
      {...formProps}
      className="ant-modal-content"
      style={{
        background: appModeTheme?.modalBackgroundColor,
        color: appModeTheme?.secondLayerTextColor,
        padding: 24,
        ...style
      }}
      layout="vertical"
      scrollToFirstError={{ behavior: "smooth" }}
    >
      <>
        {!withoutHeader && (
          <>
            <h2
              data-testid="form-in-modal-header-title"
              style={{ color: "inherit", textAlign: "center" }}
            >
              {title}
            </h2>
            <p style={{ color: "inherit" }}>{text}</p>
          </>
        )}

        {children}
      </>
    </Form>
  );
}

import type { FormInstance, RadioChangeEvent } from "antd";
import { useState } from "react";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import useThemedModal from "@theme/hook/useThemedModal";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";

import { InvestmentRiskForm } from "../InvestmentRiskForm";

export const useValidationHandler = (
  form: FormInstance,
  strategy: FinancialPositionDto["strategy"] | undefined,
  userUri: string
) => {
  const { contextHolder, openModal } = useThemedModal();

  const labels = useOnboardingLabels();

  const [existingInstance, setModalInstance] = useState<{
    destroy: () => void;
    update: (configUpdate: any) => void;
  }>();

  const onStrategyChange = async ({ target: { value } }: RadioChangeEvent) => {
    const validateResult = await smavestoCore.services.validation.validate(
      "validate-anlageziel",
      `anlageziel=${value}`,
      userUri
    );

    if (isApiErrorResponse(validateResult)) {
      if (existingInstance) existingInstance.destroy();

      const modalInstance = openModal("confirm", {
        ...labels.investmentSettings.warningModalSettings.strategy,
        modalRender: () => (
          <InvestmentRiskForm
            currentStrategy={value}
            destroyModal={() => modalInstance.destroy()}
            onResetParentField={() => {
              form.resetFields(["strategy"]);
              form.setFieldsValue({ strategy });
            }}
          />
        )
      });

      setModalInstance(modalInstance);
    }
  };

  return {
    onStrategyChange,
    contextHolder
  };
};

import type { SliderSingleProps } from "antd";
import styled from "styled-components";

import PopoverInfo from "@components/form/popover-info/PopoverInfo";
import type { PropsWithChildren } from "@src/interfaces/jsx.interface";
import { SSlider } from "@styled-components/form/SFormItems";

import useIsSmavestoPremium from "@src/hooks/supplier/useIsSmavestoPremium";
import { getTextForInfo, parseRiskNumValueToLabel } from "./helpers";
import SmavestoPremiumRiskFeeInfo from "./SmavestoPremiumRiskFeeInfo";

interface RiskSliderProps extends SliderSingleProps, PropsWithChildren {
  isUnderage: boolean;
  value?: number;
  margin?: string;
  labelMargin?: string;
}

export const RiskSlider: React.FunctionComponent<RiskSliderProps> = ({
  value,
  margin,
  labelMargin,
  children,
  isUnderage,
  ...sliderProps
}) => {
  const label = parseRiskNumValueToLabel(value ?? 0);

  const isSmavestoPremium = useIsSmavestoPremium();

  return (
    <>
      {children}
      <SRiskSliderLabel labelMargin={labelMargin}>
        <PopoverInfo
          placement="bottom"
          text={getTextForInfo(label, isUnderage)}
          title={label}
        >
          {label}{" "}
        </PopoverInfo>{" "}
        {isSmavestoPremium && (
          <SmavestoPremiumRiskFeeInfo selectedRiskLabel={label} />
        )}
      </SRiskSliderLabel>

      <SSlider {...sliderProps} value={value} margin={margin} />
    </>
  );
};

const SRiskSliderLabel = styled.p<{ labelMargin?: string }>`
  font-weight: bold;
  text-align: center;
  color: inherit;

  margin: ${({ labelMargin = "0 0 60px 0" }) => labelMargin};
`;

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import onboardingUnderageLabels from "@page-components/onboarding-underage/labels/onboardingUnderageLabels";
import { useIsUnderageMode } from "@page-components/onboarding/hooks";
import onboardingLabels from "@page-components/onboarding/investment-settings/labels/onboardingLabels";
import {
  currentDepotIsUnderageState,
  isUnderageUserUriState
} from "@states/depot.state";

function useOnboardingLabels(
  source?: "dashboard" | "onboarding" | "userUri",
  userUri?: string
) {
  const isUnderageMode = useIsUnderageMode();

  const [, userUriIsUnderage] = useAsyncSelector(
    isUnderageUserUriState(userUri || "")
  );

  const [, currentDepotIsUnderage] = useAsyncSelector(
    currentDepotIsUnderageState
  );

  if (!source || source === "onboarding") {
    if (isUnderageMode === true) {
      return onboardingUnderageLabels;
    }
    return onboardingLabels;
  }
  if (source === "userUri") {
    if (userUriIsUnderage) {
      return onboardingUnderageLabels;
    }
    if (!userUriIsUnderage) {
      return onboardingLabels;
    }
  }

  if (currentDepotIsUnderage) {
    return onboardingUnderageLabels;
  }
  return onboardingLabels;
}

export default useOnboardingLabels;

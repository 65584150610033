import PopoverInfo from "@src/components/form/popover-info/PopoverInfo";
import smavestoPremiumRiskFees from "@src/utils/cost-calculation/static/smavestoPremiumRiskFees";
import formatNumber from "@src/utils/format/formatNumber";

interface SmavestoPremiumRiskFeeInfoProps {
  selectedRiskLabel:
    | "Konservativ"
    | "Gewinnorientiert"
    | "Risikobewusst"
    | "Spekulativ"
    | "Sicherheitsorientiert";
  isUnderage?: boolean;
}

const SmavestoPremiumRiskFeeInfo: React.FunctionComponent<
  SmavestoPremiumRiskFeeInfoProps
> = ({ selectedRiskLabel, isUnderage }) => {
  const feeForRiskLevel = smavestoPremiumRiskFees[selectedRiskLabel];

  if (isUnderage)
    return (
      <PopoverInfo
        placement="bottom"
        text="Die Vermögensverwaltungsgebühr Ihres Kindes richtet sich nach der Risikoklasse Ihres Kindes: Konservativ beträgt 1,20% p.a., Gewinnorientiert beträgt 1,40% p.a., Risikobewusst beträgt 1,60% p.a., Spekulativ beträgt 1,80% p.a.."
        title="Vermögensverwaltungsgebühr"
      >
        Vermögensverwaltungsgebühr:{" "}
        {formatNumber(feeForRiskLevel.feePercentPerYear, 2)}% p.a.
      </PopoverInfo>
    );

  return (
    <PopoverInfo
      placement="bottom"
      text="Ihre Vermögensverwaltungsgebühr richtet sich nach Ihrer Risikoklasse: Konservativ beträgt 1,20% p.a., Gewinnorientiert beträgt 1,40% p.a., Risikobewusst beträgt 1,60% p.a., Spekulativ beträgt 1,80% p.a.."
      title="Vermögensverwaltungsgebühr"
    >
      Vermögensverwaltungsgebühr:{" "}
      {formatNumber(feeForRiskLevel.feePercentPerYear, 2)}% p.a.
    </PopoverInfo>
  );
};

export default SmavestoPremiumRiskFeeInfo;

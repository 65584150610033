import type { InputNumberProps } from "antd";
import { useContext, type FC, type Ref } from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import { SInputNumber } from "@styled-components/form/SInputs";
import { parseCurrencyStringToNumber } from "@utils/api/inputHelper";
import { ThemeContext } from "@src/pages/_app";

interface CurrencyInputPropsProps<T extends string | number>
  extends ICompBaseProps,
    InputNumberProps<T> {
  ref?: Ref<HTMLInputElement>;
}
function CurrencyInput<T extends string | number>(
  props: CurrencyInputPropsProps<T>
) {
  const { inputCssProperties } = useContext(ThemeContext);

  return (
    <SInputNumber<FC<InputNumberProps<T>> | string>
      {...props}
      style={inputCssProperties}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      parser={value =>
        (value ? parseCurrencyStringToNumber(value) : undefined) as T
      }
      addonAfter="€"
    />
  );
}

export default CurrencyInput;

import React from "react";

interface RiskValidateModalContentProps {
  isUnderage: boolean;
}

export const RiskValidateModalContent: React.FunctionComponent<
  RiskValidateModalContentProps
> = ({ isUnderage }) => (
  <>
    {isUnderage ? (
      <p>
        Sie können oder wollen für Ihr Kind Kursrisiken und mögliche Verluste
        nicht tragen? In diesem Fall ist eine Investition in Wertpapiere für Ihr
        Kind womöglich nicht geeignet.
      </p>
    ) : (
      <p>
        Sie können oder wollen Kursrisiken und mögliche Verluste nicht tragen?
        In diesem Fall ist eine Investition in Wertpapieren für Sie womöglich
        nicht geeignet.
      </p>
    )}
    <p>
      Wir bei Smavesto glauben, dass durch ein cleveres Management die Chancen
      auf Rendite an den Kapitalmärkten die damit verbundenen Risiken
      langfristig überwiegen.
    </p>
    {isUnderage ? (
      <p>
        Wenn Sie sich mit den Risiken einer Geldanlage in Wertpapieren nicht
        wohlfühlen, sollten Sie beispielsweise überlegen, eine bestimme Summe
        auf einem Tagesgeldkonto für Ihr Kind anzulegen.
      </p>
    ) : (
      <p>
        Wenn Sie sich mit den Risiken einer Geldanlage in Wertpapieren nicht
        wohlfühlen, sollten Sie beispielsweise überlegen, eine bestimmte Summe
        auf einem Tagesgeldkonto anzulegen. Hierzu informieren Sie gern Ihre
        Sparkasse oder andere Geldinstitute.
      </p>
    )}
  </>
);

import { FormInModal } from "@components/form/inputs/form-fields/FormInModal";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SFormItem } from "@styled-components/form/SFormItems";

import { RiskSlider } from "./RiskSlider";
import { useIsUnderageMode } from "../../hooks";
import { useInvestmentRiskForm } from "./bus/useInvestmentRiskForm";

interface InvestmentRiskFormProps {
  currentStrategy: FinancialPositionDto["strategy"];
  destroyModal: () => void;
  onResetParentField: () => void;
}

export const InvestmentRiskForm: React.FunctionComponent<
  InvestmentRiskFormProps
> = ({ currentStrategy, destroyModal, onResetParentField }) => {
  const {
    riskInitialValue,
    form,
    onSliderAfterChange,
    onFinish,
    onCancel,
    isSubmit,
    isValidRiskValue,
    contextHolder
  } = useInvestmentRiskForm(currentStrategy, destroyModal, onResetParentField);

  const labels = useOnboardingLabels();

  const isUnderage = useIsUnderageMode();

  return (
    <FormInModal<{ investmentRisk: number }>
      form={form}
      onFinish={onFinish}
      title="Achtung!"
      text={labels.investmentSettings.strategyModalWarning}
    >
      <SFormItem name="investmentRisk" initialValue={riskInitialValue}>
        <RiskSlider
          isUnderage={isUnderage}
          marks={labels.investmentSettings.investmentRiskMarks}
          min={0}
          max={40}
          step={1}
          margin="16px 0 28px 0"
          labelMargin="0 0 40px 0"
          tipFormatter={(value?: number) => `${value ?? 0}% max. Drawdown`}
          onAfterChange={onSliderAfterChange}
        />
      </SFormItem>
      <SActionButtonsWrapper style={{ margin: 0, marginTop: 32 }}>
        <SButton
          className="reset"
          onClick={onCancel}
          data-testid="close-risk-modal"
        >
          Abbrechen
        </SButton>

        <SButton
          htmlType="submit"
          type="primary"
          disabled={isSubmit || !isValidRiskValue}
          loading={isSubmit}
        >
          Anlagerisiko ändern
        </SButton>
      </SActionButtonsWrapper>
      {contextHolder}
    </FormInModal>
  );
};

const locale = "de-DE";

export const parseStringToNumber = (input: string | number) =>
  typeof input === "string"
    ? parseFloat(
        `${input}`.replace(/,/, "#").replace(/\./g, "").replace(/#/, ".")
      )
    : input;

export const parseCurrencyStringToNumber = (
  value: string
): number | undefined => {
  // code from https://ant.design/components/input-number/
  try {
    // for when the input gets clears
    if (typeof value === "string" && !value.length) {
      return undefined;
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
    let reversedVal = value.replace(new RegExp(`\\${group}`, "g"), "");
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    let result = Number.parseInt(reversedVal, 10);
    if (needsDigitsAppended) {
      result *= 10 ** (digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(result) ? 0 : result;
  } catch (error) {
    return 0;
  }
};

const smavestoPremiumRiskFees = {
  Sicherheitsorientiert: {
    feePercentPerYear: 1.2
  },
  Konservativ: {
    feePercentPerYear: 1.2
  },
  Gewinnorientiert: {
    feePercentPerYear: 1.4
  },
  Risikobewusst: {
    feePercentPerYear: 1.6
  },
  Spekulativ: {
    feePercentPerYear: 1.8
  }
};

export default smavestoPremiumRiskFees;

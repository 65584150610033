import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

export const isValidRiskToStrategy = (
  strategy: FinancialPositionDto["strategy"],
  risk: number
) =>
  ({
    HIGH_RISK: risk >= 35,
    MEDIUM_RISK: risk <= 40,
    NO_RISK: risk <= 40
  })[strategy] ?? false;
